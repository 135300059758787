.transaction-lines-title {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0 1rem;
}

.transaction-line {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #F2F3F5;
    border-left: 1px solid #F2F3F5;
    border-right: 1px solid #F2F3F5;
    line-height: 21px;
    padding-left: 0;
    padding-right: 0;
}

.transaction-line-col1 {
    width: 20%;
    padding-left: 2%;
}

.transaction-line-col2 {
    width: 28%;
    padding-right: 4%;
}

.transaction-line-col3 {
    width: 32%;
    padding-right: 4%;
}

.transaction-line-col4 {
    display: flex;
    justify-content: flex-start;
    width: 8%;
}

.transaction-line-col4-full {
    display: flex;
    justify-content: flex-start;
    width: 15%;
}

.transaction-line-col5 {
    width: 5%;
    display: flex;
    justify-content: center;
}

.transaction-line-col5-icon {
    cursor: pointer;
}

.transaction-line-col6 {
    justify-content: flex-end;
    width: 7%;
    display: flex;
}

.transaction-line-col-content {
    display: flex;
    justify-content: space-between;
}

.transaction-line-col-step {
    width: 10%
}

.transaction-lines-statitistic {
    background: rgba(242, 243, 245, 0.3);
    border-bottom: 1px solid #F2F3F5;
    border-left: 1px solid #F2F3F5;
    border-right: 1px solid #F2F3F5;
    font-weight: normal;
    font-size: 14px;
}

.transaction-lines-statitistic-end {
    background: #F2F3F5;
    border-bottom: 1px solid #F2F3F5;
    border-left: 1px solid #F2F3F5;
    border-right: 1px solid #F2F3F5;
    font-weight: bold;
    font-size: 14px;
}

.transaction-line-flex {
    display: flex;
}