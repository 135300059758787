.step {
    padding-left: 1rem;
}

.checkbox-wrapper {
    display: flex;
}

.round {
    position: relative;
    padding-left: 30px;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
    margin-left: 20px;
}

.round label:hover {
    border-color: #D4001E;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 10px;
}

.round input[type="checkbox"] {
    visibility: hidden;
    width: 20px;
}

.round input[type="checkbox"]:checked+label {
    background-color: #D4001E;
    border-color: #D4001E;
}

.round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

.user-input {
    min-width: 20rem;
}

@media (min-width: 576px) {
    .my-modal-dialog {
        max-width: 50rem;
    }
}

.my-form {
    max-width: inherit;
    margin-top: 1rem;
}

.form-step {
    margin-top: 1.5rem;
}

.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
}

.modal-body {
    padding: 2rem;
}

.input-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.001em;
    color: #96999F;
}

.add-user-button-container {
    display: flex;
    justify-content: flex-end !important;
    margin-top: 1.5rem;
}

.title-nav {
    display: flex;
}

.title-container {
    display: flex;
    justify-content: space-between;
}

.cross {
    background-image: url("../../../assets/Plus.svg");
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}

.cross:hover {
    cursor: pointer;
}

.secure-text {
    font-family: 'text-security-disc';
}

.password-eye {
    background-image: url("../../../assets/Eye.svg");
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin-top: 0.8rem;
    position: absolute;
}

.password-eye:hover {
    cursor: pointer;
}

.password-container {
    display: flex;
    justify-content: flex-end;
}

.user-active {
    display: flex;
    align-items: center;
}

.user-active-text {
    margin-left: 0.5rem;
    display: flex;
    justify-content: center;
}

.user-active-active {
    display: flex;
    justify-content: center;
}

.flex-container {
    display: flex;
    align-items: center;
}

.add-user-error-wrap {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.add-user-error {
    margin-top: 0 !important
}

@media (max-width: 767px) {
    .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        margin-left: 0.5rem;
    }
    .add-user-button-container {
        display: flex;
        justify-content: space-between !important;
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }
    .title-container {
        border-bottom: solid 1px $thm-grey;
        padding-bottom: 1rem;
    }
}