.table-checkbox {
    width: auto;
}

.table-head {
    border-top: none;
    background-color: $thm-grey;
}

.icon-step {
    margin-right: 0.5rem;
}

.my-table {
    margin-top: 1rem;
}

.my-table thead th {
    border-bottom: none;
    border-top: none;
}

// .my-table tbody tr {
//     border-bottom: 1px solid #dee2e6;
// }
.my-table tbody tr:hover {
    background-color: $thm-grey;
}

.add-product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
}

@media (max-width: 321px) {
    .add-product {
        justify-content: unset;
    }
}

.add-product-icon {
    background-image: url("../../assets/AddPlus.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 0.5rem;
}

.add-product-wrap {
    height: 100%;
}

.add-product-split-line {
    width: 2px;
    height: $input-default-height;
    border-left: solid 2px $thm-grey;
}

.input-container {
    margin: 0;
    width: $input-default-width;
    height: $input-default-height;
    display: flex;
    align-items: center;
}

.products-filters-add-products {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
}

.search-icon {
    background-image: url("../../assets/Search.svg");
    background-repeat: no-repeat;
    padding-left: 30px;
    width: 24px;
    height: 24px;
}

.input-search[type='text'] {
    background-image: url("../../assets/Search.svg");
    background-position: 7px 7px;
    background-repeat: no-repeat;
    margin: 0;
}

.input-search {
    padding-left: 2.3rem;
}

.padding-container {
    padding: 0 $padding-mobile;
}

.line {
    border-bottom: solid 1px $thm-grey;
    width: 100%;
    height: 1px;
}

.certificate-url {
    color: #000;
    text-decoration: underline;
}

.certificate-url:hover {
    cursor: pointer;
}