.collapse-header {
    display: flex;
    justify-content: space-between;
    height: 5.5rem;
    padding: 0 $padding-mobile;
}

.collapse-header-border {
    border-bottom: solid 1px $thm-grey;
}

.collapse-content {
    padding: 0 $padding-mobile
}

.collapse-active {
    background-color: #fff !important;
    border: none;
}

.collapse-active-border {
    border-bottom: solid 1px $thm-grey;
}

.collapse-header-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.collapse-header-price {
    font-size: 14px;
    line-height: 17px;
    color: #cfcccc
}

.collapse-header {
    display: flex;
    align-items: center;
    background-color: rgba(242, 243, 245, 0.3);
    width: 100%;
    justify-content: space-between;
}

.collapse-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.collapse-row-end {
    padding: 1rem;
    padding-right: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.collapse-buttons {
    display: flex;
    justify-content: space-between;
    width: 40%;
}

@media (max-width: 1024px) and (min-width: 768px) {
    .collapse-buttons {
        width: 25%;
    }
}

.collapse-text {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: $thm-red;
}

.collaplse-tr {
    border: hidden;
}