.transactions-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.transactions-tabs-wrap {
    display: flex;
    justify-content: space-between;
    width: 8rem;
}

.transaction-tab {
    line-height: 17px;
    color: #999999;
    height: 1.6rem;
    cursor: pointer;
}

.transaction-tab-active {
    border-bottom: 2px solid $thm-red;
    color: #000;
}

.transaction-back-arrow {
    cursor: pointer;
}

.archive-icon {
    background-image: url("../../assets/Archive.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.date-search-field {
    margin: 0 0.5rem
}

.date-search-container {
    display: flex;
    margin-left: 1.5rem;
}

.transactions-flex-center {
    display: flex;
    align-items: center;
}