.transaction-add-edit-back {
    display: flex;
}

.transaction-add-edit-back-text {
    margin-left: 0.5rem;
}

.transaction-form-container {
    margin-top: 1.5rem;
}

.certificate-block-container {
    padding: 1.5rem;
    border: 1px solid #f2f3f5;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 1rem;
    background: rgba(242, 243, 245, 0.3);
}

@media (max-width: 767px) {
    .certificate-block-container {
        padding: 1.5rem 0.5rem;
    }
}

@media (max-width: 321px) {
    .add-transaction-line-text {
        display: none;
    }
}

.transaction-id {
    margin-top: 0.5rem;
}

.certificate-block-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.001em;
}

.certificate-block-step-top {
    margin-top: 1rem;
}

.certificate-block-container-end {
    margin-bottom: 2rem;
}

.certificate-content-step {
    margin-top: 0.5rem;
}

.input-feedback-textarea {
    margin-top: 5px;
}

.transaction-icon-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
}

.transaction-icon-text {
    margin: 0 0.5rem;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-align: center;
}

.transaction-title-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem;
    margin-top: 1rem;
}

.transaction-form {
    width: inherit;

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        max-width: 100%;
    }

    max-width: unset;
}

.select-product-input {
    max-width: 30rem;
}

.transaction-add-product {
    margin-left: 0.5rem;
    min-width: 7.5rem;
}

.select-product-input-container {
    display: flex;
    align-items: center;
}

.react-datepicker-wrapper {
    width: 100%;
}

.transaction-disabled {
    background: $thm-grey !important;
}

.add-product-dropdown {
    background: #ffffff;
    border: 1px solid #dadce1;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
}

.sub-text {
    font-size: 12px;
    color: #cfcccc;
}

.icon-margin {
    margin: 0 0.5rem;
}

.transaction-state {
    height: calc(1.5em + 0.75rem + 2px);
}

.copy-company-data {
    text-decoration: underline;
}

.copy-company-data {
    cursor: pointer;
}

.transaction-editor-disabled {
    background-color: $thm-grey;
    pointer-events: none;
}

.transaction-padding-left {
    padding-left: 15px
}

.transaction-flex-center {
    display: flex;
    align-items: center;
}

.certificate-block-bold {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: bold;
}

.transaction-dropdown-padding {
    padding: 0 0.5rem;
    padding-right: 6rem;
    min-height: 3rem;
}