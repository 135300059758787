.inputs-head {
    background-color: #fff;
    margin: 0;
}

.user-input-search {
    margin: 0;
}

.input-search-date[type='text'] {
    background-image: url("../../../assets/Date.svg");
    background-position: 7px 7px;
    background-repeat: no-repeat;
    margin: 0;
    min-width: 9rem;
}