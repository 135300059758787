.items-per-page-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.items-per-page-box {
    height: 24px;
    margin-left: 0.6rem;
}

.items-per-page {
    width: 50px;
    height: 24px;
    border: 1px solid #C1C4C9;
    box-sizing: border-box;
    text-align: center;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
}

.items-per-page-text {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #999999;
}

.collapse-container {
    margin-top: 1rem;
}

.pagination-step {
    margin-top: 1.5rem;
}

.pagination-hide {
    display: none;
}

.small-th {
    width: 6rem
}

.collapse-header-type {
    font-size: 14px;
    line-height: 17px;
    color: #cfcccc;
}

.disabled-user-raw {
    background-color: #FAFAFA;
}

@media (max-width: 1024px) {
    .items-per-page {
        width: 3rem;
        height: 3rem;
        margin: 0;
    }

    .items-per-page-container {
        align-items: unset;
    }
}