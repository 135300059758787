.transaction-line-mobile {
    display: flex;
    justify-content: space-between;
}

.transaction-line-mobile-title {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0.5rem;
}

.transaction-line-mobile-step {
    margin-top: 0.5rem;
}

.transaction-line-mobile-small-margin {
    margin-top: 0.5rem;
}

.transaction-line-mobile-margin {
    margin-top: 1rem;
}

.transaction-line-mobile-height-margin {
    margin-top: 1rem;
}