#password {
    font-family: 'text-security-disc';
}

.step {
    margin-top: 40px;
}

.label-input {
    padding-bottom: 20px
}

.dropzone {
    width: 100%;
    height: 6rem;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #C1C4C9;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: 0.001em;
    cursor: pointer;
}

.dropdown-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.2rem;
}

.dropdown-upload-image {
    margin-right: 0.5rem;
}

.image-container {
    margin-top: 1rem;
    max-width: 880px;
    max-height: 180px;
    overflow: overlay;
}

.image-size {
    max-width: 880px;
    max-height: 180px;
}

.image-contains-company-info {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    font-size: 16px;
}

.image-close-container {
    display: flex;
}

.white-background {
    background: white;
}

.profile-toolbarClassName {
    margin-bottom: 0;
}

.profile-margin-left {
    margin-left: 0.5rem;
}

.profile-pointer {
    cursor: pointer;
}