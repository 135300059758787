.my-checkbox {
    background-image: url("../../assets/Checkbox.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.my-checkbox-selected {
    background-image: url("../../assets/CheckboxSelected.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
}