.container {
    width: 100%;
}

.container-step {
    height: 1.5rem;
    width: 100%;
}

.full-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    flex-direction: column;
    padding-top: 3rem;
}

.layout-background {
    background: #FBFBFC;
    width: 100%;
}

@media (max-width: 767px) {
    .mobile-container {
        padding: 0;
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1440px) {
    .container {
        max-width: 1360px;
    }
}

@media (min-width: 1440px) {
    .container {
        max-width: 1360px;
    }
}

@media (min-width: 1620px) {
    .container {
        max-width: 1520px;
    }
}

.footer {
    flex: 0 0 auto;
    background: $thm-grey;
    width: 100%;
    height: 2.2rem;
    align-items: center;
    display: flex;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    padding: 0 2.0rem;
    align-self: center;
    width: 100%;
}

.footer-contact {
    display: flex;
    justify-content: space-between;
}

.footer-contact-text {
    margin-right: 1.2rem;
}

.footer-mobile {
    display: flex;
    align-items: center;
}

.footer-mobile-icon {
    background-image: url("../../assets/Mobile.svg");
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-right: 0.4rem;
    margin-top: 0.2rem;
}

.gdpr {
    margin-left: 0.6rem;
}

.gdpr a {
    color: #7B7474;
}

.copyright-container {
    display: flex;
}

@media (max-width: 360px) {
    .footer-content {
        padding: 0;
    }
}