.App {
    font-family: sans-serif;
}

h1 {
    text-align: center;
}

form {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
}

label,
input {
    display: block;
    width: 100%;
}

label {
    margin-bottom: 5px;
    height: 22px;
}

input {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #777;
}

input.error {
    border-color: red;
}

.input-feedback {
    color: #D70001;
    margin-top: -15px;
    font-size: 14px;
    margin-bottom: 20px;
}

button {
    padding: 10px 15px;
    background-color: #D70001;
    color: white;
    border: 1px solid #D70001;
    background-color: 250ms;
}

button:hover {
    cursor: pointer;
    background-color: white;
    color: #D70001;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.recovery-title {
    display: flex;
}

.recovery-img {
    margin-right: 0.5rem;
    cursor: pointer
}

.recovery-txt {
    margin-right: 0.5rem;
}