.App {
    font-family: sans-serif;
}

h1 {
    text-align: center;
}

form {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
}

label,
input {
    display: block;
    width: 100%;
}

label {
    margin-bottom: 5px;
    height: 22px;
}

input {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #777;
}

input.error {
    border-color: red;
}

.input-feedback {
    color: #D70001;
    margin-top: -15px;
    font-size: 14px;
    margin-bottom: 20px;
}

.input-feedback-nested {
    color: #D70001;
    font-size: 14px;
}

.input-feedback-contact {
    margin: 0;
    padding: 0;
}

button {
    padding: 10px 15px;
    background-color: #D70001;
    color: white;
    border: 1px solid #D70001;
    background-color: 250ms;
}

button:hover {
    cursor: pointer;
    background-color: white;
    color: #D70001;
}

.forgot-password {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

.forgot-password:hover {
    cursor: pointer;
    text-decoration: underline;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.login-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-content {
    width: 100%;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #F2F3F5;
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 30rem;
    max-width: 40rem;
}

.welcome-container {
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
}

.welcome {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
}

.bct-title {
    font-weight: bold;
    font-size: 44px;
    line-height: 53px;
    text-align: center;
    margin-top: 1rem;
}

.login-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-top: 2rem;
}

.form-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.001em;
    color: #7B7474;
}

.forgot-password-button-container {
    display: flex;
    justify-content: flex-end;
}

.login-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
}

.login-text-link {
    margin-top: 1rem;
}

.login-text-link a {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #7B7474;
}

.buttons-container {
    display: flex;
    flex-direction: column;
}

@media (max-width: 767px) {
    .login-content {
        padding: 0 1rem;
    }
}

.login-error {
    margin-top: 3rem
}