.arrow-container {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.arrow-up {
    background-image: url("../../assets/2.svg");
    background-repeat: no-repeat;
    width: 10px;
    height: 8px;
}

.arrow-down {
    background-image: url("../../assets/1.svg");
    background-repeat: no-repeat;
    width: 10px;
    height: 8px;
    margin-left: 0.5px;
}

.arrow-down:hover {
    background-image: url("../../assets/3.svg");
}

.arrow-up:hover {
    background-image: url("../../assets/4.svg");
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}