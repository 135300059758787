﻿a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.navbar {
    background: $thm-red;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.logo {
    background-image: url(../assets/Logo.svg);
    background-repeat: no-repeat;
    width: 200px;
    height: 30px;
}

.user-container {
    display: flex;
    align-items: center;
}

.user {
    background-image: url(../assets/User.svg);
    background-repeat: no-repeat;
}

.user-icon {
    width: 23px;
    height: 23px;
}

.nav-text {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-size: 16px;
    color: #FFFFFF !important;
    letter-spacing: 0.5px;
}

.nav-bold {
    font-weight: 900;
    text-decoration: underline !important;
}

.nav-logout {
    margin-left: 0.5rem;
}

.nav-item {
    margin: 0 0.8rem;
    height: 100%;
}

.my-navbar {
    margin-bottom: 0 !important;
    border-bottom: unset !important;
}

.user-dropdown {
    display: flex;
    align-items: center;
    background-color: $thm-red !important;
    border: none;
    box-shadow: none !important;
}

.user-dropdown:hover {
    background-color: $thm-red !important;
    box-shadow: none !important;
}

.user-dropdown:focus {
    background-color: $thm-red !important;
    box-shadow: none !important;
}

.user-dropdown:active {
    background-color: $thm-red !important;
    box-shadow: none !important;
}

.user-acc {
    margin-left: 5px;
}

.mobile-navbar {
    background-color: #FFFFFF;
    padding: 0;
}

.mobile-navbar-item {
    color: #000 !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 0 1rem !important;
    height: 100%;
    display: flex;
    align-items: center;
}

.mobile-navbar-active {
    background-color: $thm-grey;
    border-bottom: 2px solid $thm-red;
}

.mobile-navbar-container {
    display: flex;
    height: 3.5rem;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid $thm-grey;
    padding: 0;
}

.my-dropdown {
    padding: 0;
    padding-left: 0.5rem;
}

.my-dropdown:active {
    background-color: unset;
}

@media (max-width: 576px) {
    .nav-item {
        margin: 0 0rem;
        height: 100%;
    }
}

@media (max-width: 992px) {
    .logo {
        background-image: url(../assets/Icon.svg);
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
    }
}

@media (max-width: $breakpoint-768 - 1) {
    .user-dropdown {
        padding-top: 1rem;
    }
    .mobile-user-header {
        display: none;
    }
}