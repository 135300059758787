.pagination-container {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
}

.pagination-cell {
    width: 24px;
    height: 24px;
    border: 1px solid #C1C4C9;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-cell-start {
    width: 24px;
    height: 24px;
    border: 1px solid #C1C4C9;
    box-sizing: border-box;
    text-align: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg)
}

.pagination-cell-prev {
    width: 24px;
    height: 24px;
    border: 1px solid #C1C4C9;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg)
}

.pagination-cell-end {
    width: 24px;
    height: 24px;
    border: 1px solid #C1C4C9;
    box-sizing: border-box;
    text-align: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selected-cell {
    background-color: #C1C4C9;
    color: white;
    padding: 0px !important;
}

.disabled-cell {
    color: #C1C4C9;
}

.pagination-text {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #999999;
}

.pagination {
    display: flex;
}

@media (max-width: 1024px) and (min-width: 768px) {
    .pagination-container {
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 767px) {
    .pagination-container {
        display: flex;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .pagination-text {
        margin-top: 0.5rem;
    }
}

@media (max-width: 1024px) {
    .pagination-cell {
        width: 3rem;
        height: 3rem;
    }

    .pagination-cell-start {
        width: 3rem;
        height: 3rem;
    }

    .pagination-cell-prev {
        width: 3rem;
        height: 3rem;
    }

    .pagination-cell-end {
        width: 3rem;
        height: 3rem;
    }
}