.certificate {
    padding: 2rem;
    background: rgba(242, 243, 245, 0.3);
    border: 1px solid #f2f3f5;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 1rem;
}

.certificate-input {
    max-width: 25rem;
    margin: 0;
}

.certificate-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    padding-top: 0.7rem;
}

.certificate-input-title {
    letter-spacing: 0.001em;
    line-height: 17px;
}

.certificate-block {
    background: #ffffff;
    border: 1px solid #f2f3f5;
    box-sizing: border-box;
    padding: 0 1rem;
}

.certificate-block-top {
    padding-bottom: 1rem;
    height: 2rem;
}

.certificate-col-title {
    background: #f2f3f5;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
}

.col-border-bottom {
    border-bottom: 1px solid #f2f3f5;
}

.certificate-step {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.col-wrap {
    max-width: 25rem;
    padding-top: 1rem;
}

.col-flex {
    display: flex;
}

.certificate-icon-step {
    margin-left: 0.5rem;
}

.row-icons {
    min-height: 2.5rem;
}

.certificate-other {
    margin-bottom: 1rem;
}

.certificate-textarea {
    margin: 0.8rem 0;
}

.col-padding-removed {
    padding-right: 0;
}

.certificate-col-error {
    margin: 0;
}

.row-full-width {
    width: 100%;
}

.certificate-col-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.date-picker-wrap {
    max-width: 25rem;
}

.certificate-full-width {
    width: 100%;
    max-width: unset;
}

.certificate-form-step {
    margin-top: 1rem;
}

.certificate-row-flex {}

.certificate-row-flex-nested {
    display: flex;
    align-items: center;
}

.col-measure {
    margin-left: 0.5rem;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
    opacity: 1;
}

@media (max-width: 767px) {
    .input-flex-end {
        width: 100%;
    }

    .certificate {
        padding: 0rem;
    }

    .col-border-bottom {
        border-bottom: none;
    }

    .certificate-block {
        background: #ffffff;
        border: none;
        padding-bottom: 1rem;
    }

    .certificate-col {
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
    }

    .col-flex {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .certificate-input-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.001em;
        color: #96999f;
    }
}