html,
body {
    height: 100%;
    font-family: "Lato", sans-serif;
    font-style: normal;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.notification {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.notification-body {
    color: white;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.content {
    flex: 1 0 auto;
}

input:disabled {
    background-color: $thm-grey !important;
}

textarea:disabled {
    background-color: $thm-grey !important;
}