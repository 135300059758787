.transaction-line-description {
    margin-bottom: 1rem;
}

.inline-editor {
    background: #F2F3F5;
}

.rdw-editor-toolbar {
    background: #F2F3F5;
}

.rdw-option-wrapper {
    height: 24px;
    width: 24px;
    background: #F2F3F5;
    cursor: pointer;
}

.wrapperClassName {
    border: 1px solid #DADCE1;
}

.editorClassName {
    padding: 0 0.5rem;
    min-height: 4rem;
}

.public-DraftStyleDefault-block {
    margin-top: 0;
    margin-bottom: 0.1rem;
}