.primary-button {
    background-color: $thm-red;
    border: none;
    border-radius: 4px;
    width: 8.8rem;
    height: 2.6rem;
}

.primary-button:hover {
    background-color: $thm-red-hover;
    border: none;
}

.primary-button:focus {
    background-color: $thm-red-hover;
    border: none;
    box-shadow: none;
}

.primary-button:disabled,
.primary-button[disabled] {
    background: #F2F3F5;
    color: #CDD2DA;
}

.secondary-button {
    background: #C1C4C9;
    border: 1px solid #C1C4C9;
    box-sizing: border-box;
    border-radius: 4px;
    width: 8.8rem;
    height: 2.6rem;
}

@media (max-width: 767px) {
    .primary-button {
        width: 48%;
        height: 3.2rem;
    }
    .secondary-button {
        width: 48%;
        height: 3.2rem;
    }
}